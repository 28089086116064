import styled from '@emotion/styled'
import React from 'react'
import { Button, SieClose, SiePlus } from '../../atoms'
import { FormRow } from './'

export const FormRepeat: React.FC = ({ children }) => {
  const [elements, setElements] = React.useState(['', ''])

  return (
    <>
      {elements.map((val, i) => (
        <FormRow>
          {children}
          {i ? (
            <RemoveBtn
              onClick={() => {
                setElements(
                  elements
                    .slice(0, i)
                    .concat(elements.slice(i + 1, elements.length))
                )
              }}
            >
              <SieClose />
            </RemoveBtn>
          ) : (
            ''
          )}
        </FormRow>
      ))}
      <Button
        tertiary
        icon={SiePlus}
        onClick={() => {
          setElements([...elements, ''])
        }}
      >
        Hinzufügen
      </Button>
    </>
  )
}
const RemoveBtn = styled.div(
  ({ theme: { colors } }) => `
  position:absolute;
  right:-30px;
  top:12px;
  cursor:pointer;
  &:hover{
    svg{ fill:${colors.inputBorderHover}}
  }
`
)
