import styled from '@emotion/styled'
import { TimeUtil } from 'cuenect-web-core'
import dayjs from 'dayjs'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import {
  Button,
  SieClock,
  SieClockDisable,
  Text,
  TooltipIcon,
} from '../../atoms'
import { Error } from '../../atoms/input/input'
import { mq } from './../../../utility/breakpoint'
import { DatePicker } from './../datePicker'
import { Timepicker } from './../timepicker'

export interface DateRangePickerProps {
  name: string[]
  label: string[]
  value: dayjs.Dayjs[]
  range?: dayjs.Dayjs[] | undefined
  toggle?: boolean
  onChange: Function
  isSlave?: boolean
  register: Function
  autoSortTimes?: boolean
  tooltip?: string
}

export const DateRangePicker: React.FC<DateRangePickerProps> = React.forwardRef<
  DateRangePickerProps,
  JSX.IntrinsicElements['input']
>(
  (
    {
      name,
      label,
      value: defaultValue,
      toggle = false,
      range = undefined,
      onChange = () => null,
      isSlave,
      register,
      autoSortTimes = false,
      tooltip = '',
    },
    ref
  ) => {
    const [startName, endName] = name
    const [startLabel, endLabel] = label
    const [visible, setVisible] = React.useState<boolean>(!toggle)
    const [isValid, setIsvalid] = React.useState<boolean>(true)
    const [value, setValue] = React.useState(defaultValue)

    const [startValue, endValue] = value

    React.useEffect(() => validateValue(defaultValue), [defaultValue])

    const validateValue = (val: dayjs.Dayjs[]) => {
      let start = val[0]
      let end = val[1]

      if (!autoSortTimes) {
        setIsvalid(!end.isBefore(start) && !end.isSame(start))
        start = val[0]
        end = val[1]
      } else {
        if (end.isBefore(start)) {
          start = val[1]
          end = val[0]
        }
      }

      // Check Limits
      if (range) {
        if (start.isBefore(range[0])) {
          start = range[0]
        }
        if (start.isAfter(range[1])) {
          start = range[1]
        }
        if (end.isAfter(range[1])) {
          end = range[1]
        }
        if (end.isBefore(range[0])) {
          end = range[0]
        }
      }

      // MIN MAX
      if (range) {
        if (start.isBefore(range[0])) {
          start = range[0]
        }
        if (end.isAfter(range[1])) {
          end = range[1]
        }
      }
      setValue([start, end])
      if (onChange) {
        onChange([start, end])
      }
    }

    return (
      <DateRangePickerContainer>
        <input
          value={visible ? value[0].format() : ''}
          type="hidden"
          name={name[0]}
          ref={register && register()}
        />{' '}
        <br />
        <input
          value={visible ? value[1].format() : ''}
          type="hidden"
          name={name[1]}
          ref={register && register()}
        />{' '}
        <br />
        {visible && (
          <>
            <Timepicker
              value={value[0]}
              label={startLabel}
              onChange={date => validateValue([date, endValue])}
              min={range && range[0]}
              max={range && range[1]}
            />

            <Timepicker
              value={value[1]}
              label={endLabel}
              error={!isValid}
              onChange={date => validateValue([startValue, date])}
              min={range && range[0]}
              max={range && range[1]}
            />
          </>
        )}
        {toggle && (
          <>
            {visible ? (
              <Button
                asIcon
                icon={visible ? SieClockDisable : SieClock}
                onClick={() => setVisible(!visible)}
                data-tip={'valueProps'}
              />
            ) : (
              <>
                {' '}
                <Button
                  asIcon
                  icon={visible ? SieClockDisable : SieClock}
                  onClick={() => setVisible(!visible)}
                  data-tip={tooltip}
                />
                {tooltip && (
                  <ReactTooltip effect="solid" className="customTooltip" />
                )}
              </>
            )}
          </>
        )}
      </DateRangePickerContainer>
    )
  }
)

const DateRangePickerContainer = styled.div(
  ({ theme: { colors } }) => `
  display:flex;
  position:relative;
  justify-content:space-between;
  & > div{
    margin-right:5px;
    ${mq[2]}{width:49%; margin-right:0;};
  }
`
)
